.modalvoting-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.modalvoting {
    position: relative; /* Добавьте эту строку */
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%; /* Автоматический выбор ширины */
    max-width: 550px; /* Максимальная ширина */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: #000;
    font-size: 24px;
    cursor: pointer;
}

.modalvoting h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.protocol_button {
    padding: 10px;
    margin: 5px;
    cursor: pointer;
}

.protocol_button.filled-by-current-judge {
    background-color: green;
    color: white;
    cursor: not-allowed;
}

.protocol_button.filled-by-other-judge {
    background-color: red;
    color: white;
    cursor: not-allowed;
}

.protocol_button.allow-multiple {
    background-color: blue;
    color: white;
    cursor: pointer;
}

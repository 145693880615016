.centered {
    display: flex;
    justify-content: center; /* Центрирование по горизонтали */
    align-items: center; /* Центрирование по вертикали */
    min-height: 100vh; /* Высота экрана */
}

.table {
    width: 100%;
}

.paid-row {
    background-color: green;
    color: white;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.button-blue {
    background-color: rgba(255, 238, 0, 0.842);
}

.nav-link.active {
    color: #ff4081 !important; /* Розовый цвет текста для активного элемента */
    font-weight: bold; /* Жирный шрифт для активного элемента */
    position: relative;
}

.nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -5px; /* Линия под текстом */
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ff4081; /* Розовая линия */
}

.custom_toggle {
    background-color: #ff6347; /* Tomato color */
    color: white;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.info-section {
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-end;
}

.form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 150px; /* Минимальная ширина для всех групп */
}

.form-label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.form-group.exercise-group {
    flex: 2; /* Увеличивает ширину для поля "Упражнение" */
    min-width: 400px; /* Начальная ширина для поля "Упражнение" */
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.custom-table th,
.custom-table td {
    padding: 12px;
    border: 1px solid #ccc;
    text-align: left;
}

.custom-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.button-group {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.button.primary {
    background-color: #007bff;
    color: white;
}

.button.secondary {
    background-color: #6c757d;
    color: white;
}

.button.success {
    background-color: #28a745;
    color: white;
}

.button:hover {
    opacity: 0.9;
}

.pdf-input-style {
    line-height: 1.5; /* Вы можете отрегулировать значение по необходимости */
    padding: 0;
    margin: 0;
    height: auto;
}

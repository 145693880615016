.modal-content {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    padding: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.modal-content h2 {
    margin-left: 13%;
}

.protocol-table {
    width: 75%;
    margin: 0 auto;
    border-collapse: collapse;
    margin-top: 20px;
}

.protocol-table th,
.protocol-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.elementname-table {
    width: 40%;
}
.maxstore-table {
    width: 5%;
}
.score-table {
    width: 10%;
}

.score-table {
    width: 10%;
}

.submit-score,
.update-score,
.delete-score {
    margin-top: 20px;
    margin-right: 13%;
    float: right;
}

.update-score {
    background-color: #28a745;
}

.delete-score {
    background-color: #dc3545;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: #000;
    font-size: 24px;
    cursor: pointer;
}

.error-message {
    color: red;
    font-size: 16px;
    margin-bottom: 20px;
}
